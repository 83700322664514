import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'; // Import useLocation
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './Pages.css';
import { useAuth } from '../../AuthContext.js';
import Button from '../Button.js';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
  function SellerCollabPage() {
    const query = useQuery();
    const collabID = query.get('id'); // Now correctly using useLocation and URLSearchParams
    const [collab, setCollab] = useState(null);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

  useEffect(() => {
    const fetchCollabDetails = async () => {
      try {
        const collabRef = firebase.database().ref(`/uniqueCollabs/${collabID}`);
        const snapshot = await collabRef.once('value');
        if (snapshot.exists()) {
          setCollab(snapshot.val());
        } else {
          console.log("Collab not found");
        }
      } catch (error) {
        console.error("Error fetching collab details:", error);
      }
    };

    if (collabID) {
      fetchCollabDetails();
    }
  }, [collabID]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!firebase.auth().currentUser) {
      alert("Please sign in to continue.");
      setLoading(false);
      return;
    }

    // Check if no new files are selected
    if (files.length === 0) {
      alert("Please attach at least one file to complete the transaction.");
      return; // Stop the form submission if no files are attached
    }

    if (files.length > 0) {
      setLoading(true); // Show loading indicator if necessary
  
      try {
        const newFileUrls = await uploadFiles(files, collabID);
        const collabRef = firebase.database().ref(`/uniqueCollabs/${collabID}`);
        await collabRef.update({
          finalFileUrls: newFileUrls, // Assuming you're adding to a new property for final URLs
          collabStatus: 'completed',
        });
  
        alert(`Order processed, you can expect your money within 5 days.`);
        navigate('/dashboard'); // Adjust as needed based on your routing
  
      } catch (error) {
        console.error("Error during form submission:", error);
      } finally {
        setLoading(false); // Hide loading indicator
      }
    } else {
      console.log("No new files selected");
    }
  };

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  async function uploadFiles(files, transactionId) {
    const uploadTasks = Array.from(files).map((file) => {
      const fileRef = firebase.storage().ref(`sharedFiles/${file.name}`);
      return fileRef.put(file).then(snapshot => snapshot.ref.getDownloadURL());
    });
  
    const fileUrls = await Promise.all(uploadTasks);
    // Consider saving file metadata to your database if needed
    return fileUrls;
  }

  if (!collab) {
    return <div>Loading...</div>;
  }

    return (
        <div className="GenPage">
          <div className="container">
            <h1>Collab Details</h1>
            <form onSubmit={handleFormSubmit}>
              <p className="info">Seller:&nbsp;<span className="dynamic-text">{collab.sellerEmail}</span></p>
              <p className="info">Buyer:&nbsp;<span className="dynamic-text">{collab.buyerEmail}</span></p> {/* Replace with actual buyer email */}
              <p className="info">Seller Description:&nbsp;<span className="dynamic-text">{collab.description}</span></p>
              <p className="info">Your Message:&nbsp;<span className="dynamic-text">{collab.message}</span></p>
              <p className="info">Delivery Date:&nbsp;<span className="dynamic-text">{collab.deadline ? new Date(collab.deadline).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }) : 'Not specified'}</span></p>
              <p className="info">Buyer files:</p>
              <div className="audio-player">
                {collab.fileUrls && collab.fileUrls.map(url => (
                  <audio key={url} controls>
                    <source src={url} type="audio/mp3" />
                    Your browser does not support the audio tag.
                  </audio>
                ))}
              </div>
              <p className="info">Add final files:</p>
              <input
              type="file"
              className="input file-input"
              multiple
              onChange={handleFileChange}/>
              <Button type="submit" className="GenLinkButton">Complete Transaction</Button>
            </form>
          </div>
        </div>
      );
    }
    
    export default SellerCollabPage;
    
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'; // Import useLocation
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './Pages.css';
import { useAuth } from '../../AuthContext.js';
import Button from '../Button.js';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
  function CollabPage() {
    const query = useQuery();
  const collabID = query.get('id'); // Now correctly using useLocation and URLSearchParams
  const [collab, setCollab] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCollabDetails = async () => {
      try {
        const collabRef = firebase.database().ref(`/uniqueCollabs/${collabID}`);
        const snapshot = await collabRef.once('value');
        if (snapshot.exists()) {
          setCollab(snapshot.val());
        } else {
          console.log("Collab not found");
        }
      } catch (error) {
        console.error("Error fetching collab details:", error);
      }
    };

    if (collabID) {
      fetchCollabDetails();
    }
  }, [collabID]);
  

  if (!collab) {
    return <div>Loading...</div>;
  }


    return (
        <div className="GenPage">
          <div className="container">
            <h1>Final Collab Details</h1>
            <p className="info">Seller: <span className="dynamic-text">{collab.sellerEmail}</span></p>
            <p className="info">Buyer: <span className="dynamic-text">{collab.buyerEmail}</span></p> {/* Replace with actual buyer email */}
            <p className="info">Seller Description: <span className="dynamic-text">{collab.description}</span></p>
            <p className="info">Your Message: <span className="dynamic-text">{collab.message}</span></p>
            <p className="info">Deadline: <span className="dynamic-text">{collab.deadline ? new Date(collab.deadline).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }) : 'Not specified'}</span></p>
    
            <p className="info">Buyer Files:</p>
            <div className="audio-player">
              {collab.fileUrls && collab.fileUrls.map(url => (
                <audio key={url} controls>
                  <source src={url} type="audio/mp3" />
                  Your browser does not support the audio tag.
                </audio>
              ))}
            </div>
            <p className="info">Seller Files:</p>
            <div className="audio-player">
              {collab.fileUrls && collab.finalFileUrls.map(url => (
                <audio key={url} controls>
                  <source src={url} type="audio/mp3" />
                  Your browser does not support the audio tag.
                </audio>
              ))}
            </div>
          </div>
        </div>
      );
    }
    
    export default CollabPage;
    
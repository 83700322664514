import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext.js';
import Collab from './HomeComponents/components/Collab.js';
import Comparison from './HomeComponents/components/Comparison.js';
import Video from './HomeComponents/components/Video.js';
import Header from './HomeComponents/components/Header.js';
import './HomeComponents/Home.css';

function Home() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  return (
    <div className="home">
      <div className="bootstrap-wrapper">
        <Header />
        <Collab />
        <Comparison />
        {/*<Video />*/}
        </div>
    </div>
  );
}

export default Home;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './Pages.css';
import { useAuth } from '../../AuthContext.js';
import Button from '../Button.js';
import PayPalButton from '../PayPalButton.js';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ReviewCollab() {
  const query = useQuery();
  const collabID = query.get('id');
  const [collab, setCollab] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCollabDetails = async () => {
      try {
        const collabRef = firebase.database().ref(`/uniqueCollabs/${collabID}`);
        const snapshot = await collabRef.once('value');
        if (snapshot.exists()) {
          setCollab(snapshot.val());
        } else {
          console.log("Collab not found");
        }
      } catch (error) {
        console.error("Error fetching collab details:", error);
      }
    };

    if (collabID) {
      fetchCollabDetails();
    }
  }, [collabID]);

  const handlePaymentSuccess = () => {
    alert(`Order processed, you can expect your collaboration within ${collab.deliveryTime} days or you get your money back.`);
    navigate('/dashboard');
  };

  if (!collab) {
    return <div>Loading...</div>;
  }

  console.log("Collab amount: ", collab.amount); // Debugging log

  const finalAmount = (collab.amount * 1.12).toFixed(2)

  return (
    <div className="GenPage">
      <div className="container">
        <h1>Review and Pay</h1>
        <p className="info">Seller:&nbsp;<span className="dynamic-text">{collab.sellerEmail}</span></p>
        <p className="info">Buyer:&nbsp;<span className="dynamic-text">{collab.buyerEmail}</span></p>
        <p className="info">Seller Description:&nbsp;<span className="dynamic-text">{collab.description}</span></p>
        <p className="info">Your Message:&nbsp;<span className="dynamic-text">{collab.message}</span></p>
        <p className="info">Delivery Date:&nbsp;<span className="dynamic-text">{collab.deadline ? new Date(collab.deadline).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric',}) : 'Not specified'}</span></p>
        <p className="info">Cost: $<span className="dynamic-text">{collab.amount}</span></p>
        <p className="info">Final Cost: $<span className="dynamic-text">{finalAmount}</span></p>
        <p className="info">Files:</p>
        <div className="audio-player">
          {collab.fileUrls && collab.fileUrls.map(url => (
            <audio key={url} controls>
              <source src={url} type="audio/mp3" />
              Your browser does not support the audio tag.
            </audio>
          ))}
        </div>
        <PayPalButton amount={finalAmount} collabId={collab.id} onSuccess={handlePaymentSuccess} />
      </div>
    </div>
  );
}

export default ReviewCollab;

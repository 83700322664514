import './App.css';
import React from 'react';
import NavBar from './components/NavBar.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext.js';
import Home from './components/pages/Home.js';
import Dashboard from './components/pages/Dashboard.js';
import CreateNewCollabTemplate from './components/pages/CreateNewCollabTemplate.js';
import CreateUniqueCollab from './components/pages/CreateUniqueCollab.js';
import ReviewCollab from './components/pages/ReviewCollab.js';
import BuyerCollabPage from './components/pages/BuyerCollabPage.js';
import SellerCollabPage from './components/pages/SellerCollabPage.js';
import CollabPage from './components/pages/CollabPage.js';


function App() {
  return (
    <>
    <AuthProvider>
      <Router>
        <NavBar></NavBar>
        <div className="main-content">
          <Routes className="main-content">
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/createnewcollabtemplate" element={<CreateNewCollabTemplate />} />
              <Route path="/createuniquecollab" element={<CreateUniqueCollab />} />
              <Route path="/reviewcollab" element={<ReviewCollab />} />
              <Route path="/buyercollabpage" element={<BuyerCollabPage />} />
              <Route path="/sellercollabpage" element={<SellerCollabPage />} />
              <Route path="/collabpage" element={<CollabPage />} />
              
          </Routes>
        </div>
      </Router>
    </AuthProvider>
    </>
  );
}

export default App;

//

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './Pages.css';
import Button from '../Button.js';
import { useAuth } from '../../AuthContext.js';

const WEBSITEURL = 'speedlab.pro'

function CreateNewCollabTemplate() {
  const [transactionDescription, setTransactionDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [deliveryTime, setDeliveryTime] = useState('');
  const navigate = useNavigate();

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const myUser = firebase.auth().currentUser;
    if (!myUser) {
      alert("Please sign in to continue.");
      return;
    }

    // 2. Check if there is no description
    if (!transactionDescription.trim()) {
      alert("Please enter a description for the transaction.");
      return;
    }

    // 3. Check if there is no amount or the amount is not an integer
    if (!amount.trim() || !Number.isInteger(Number(amount)) || Number(amount) <= 0) {
      alert("Please enter a valid integer amount (USD).");
      return;
    }

    // 4. Check if the delivery time is not an integer or if it is over 180
    if (!Number.isInteger(Number(deliveryTime)) || Number(deliveryTime) > 180 || Number(deliveryTime) <= 0) {
      alert("Please enter a valid integer delivery time in days (1-180).");
      return;
    }

    const user = firebase.auth().currentUser;
    if (user && user.email) {
      const formData = {
        description: transactionDescription,
        amount: amount,
        deliveryTime: deliveryTime, // Assuming you want to save this as well
        sellerUID: user.uid,
        sellerEmail: user.email,
      };

      // Adding the data to Firebase Realtime Database
      const newTransactionRef = firebase.database().ref('/transactionTemplates').push();
      const transactionKey = newTransactionRef.key;
      newTransactionRef.set(formData).then(() => {
        console.log("Transaction data saved successfully.");
        const customLink = `${WEBSITEURL}/createuniquecollab?id=${transactionKey}`;
        console.log("Transaction link: ", customLink);

        // Assuming showPopup is a function you have to display the success message
        // showPopup("Success! Your link is: " + customLink);
        alert("Success! Send this link out to people you want to work with. ---------- Your link is: " + customLink);

        navigate('/dashboard');
      }).catch((error) => {
        console.error("Error saving transaction data: ", error);
      });
    } else {
      console.log("User not signed in");
      // Handle the case where the user is not signed in, perhaps redirect to login page
    }
  };

  return (
    <div className="GenPage">
      <div className="container custom-container">
        <h1 className='collabHeader'>Collaboration Template</h1>
        <form onSubmit={handleSubmit}>
          <textarea
            id="transactiondescription"
            className="input"
            placeholder="Transaction description"
            value={transactionDescription}
            onChange={(e) => setTransactionDescription(e.target.value)}
          ></textarea>
          <input
            id="amount"
            type="text"
            className="input"
            placeholder="Amount (USD)"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <input
            id="deliveryTime"
            type="text"
            className="input"
            placeholder="Delivery time (days)"
            value={deliveryTime}
            onChange={(e) => setDeliveryTime(e.target.value)}
          />
          <Button type="submit" className="GenLinkButton newCollabTemplateButton">Generate Transaction Link</Button>
        </form>
      </div>
    </div>
  );
}

export default CreateNewCollabTemplate;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage'; // Ensure Firebase Storage is imported for file uploads
import './Pages.css';
import Button from '../Button.js'; // Assuming Button is correctly imported

function CreateUniqueCollab() {
  const [currentUser, setCurrentUser] = useState(null);
  const [sellerEmail, setSellerEmail] = useState('');
  //const [buyerEmail, setBuyerEmail] = useState('');
  //const [buyerUID, setBuyerUID] = useState('');
  const [sellerUID, setSellerUID] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [deliveryTime, setDeliveryTime] = useState('');
  const [message, setMessage] = useState('');
  const [deadline, setDeadline] = useState('');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const transactionId = getTransactionIdFromUrl();
    if (transactionId) {
      fetchTransactionData(transactionId);
    }
  }, []);

  useEffect(() => {
    // Auth state listener
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user);
      }
    });

    return () => {
      unsubscribe(); // Clean up the subscription
    };
  }, [navigate]);
  
  const getTransactionIdFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('id');
  };

  const fetchTransactionData = (transactionId) => {
    console.log(`Fetching data for transaction ID: ${transactionId}`);
    const transactionTemplatesRef = firebase.database().ref('/transactionTemplates/' + transactionId);
    transactionTemplatesRef.once('value')
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log("Transaction data:", data);
          // Update state with transaction data
          setSellerEmail(data.sellerEmail || '');
          setSellerUID(data.sellerUID || '');
          setAmount(data.amount || '');
          setDescription(data.description || '');
          setDeliveryTime(data.deliveryTime || '');
          setMessage(data.message || ''); // Assuming there's a 'message' field to fetch
          // Update any other fields as necessary
        } else {
          console.log("Transaction ID not found in database");
        }
      })
      .catch(error => {
        console.error("Error fetching transaction data:", error);
      });
  };


  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

      // 1. Check if a user is signed out
    if (!firebase.auth().currentUser) {
      alert("Please sign in to continue.");
      setLoading(false);
      return;
    }

    // 2. Check if there is no message
    if (!message.trim()) {
      alert("Please enter a message for the collaboration.");
      setLoading(false);
      return;
    }

    // 3. Check if there is no files attached
    if (files.length === 0) {
      alert("Please attach at least one file to proceed.");
      setLoading(false);
      return;
    }

    if (files.length > 0) {
      try {
        const deliveryDays = parseInt(deliveryTime, 10);
        
        // Check if deliveryDays is a valid number
        if (deliveryTime.trim() === '' || isNaN(parseInt(deliveryTime, 10))) {
          console.log(deliveryTime)
          console.error("Invalid delivery time");
          setLoading(false);
          return;
        }

        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + deliveryDays);

        // Check if the result is a valid date
        if (isNaN(currentDate.getTime())) {
          console.error("Resulting date is invalid");
          setLoading(false);
          return;
        }

        const deadline = currentDate.toISOString();
        const fileUrls = await uploadFiles(files, getTransactionIdFromUrl());
        const collabDetails = {
          sellerEmail,
          sellerUID,
          buyerEmail: currentUser.email,
          buyerUID: currentUser.uid,
          amount,
          description,
          message,
          fileUrls,
          deadline, // Calculated deadline
          collabStatus: 'unpaid',
        };

        const collabID = await createUniqueCollab(collabDetails);
        navigate(`/reviewcollab?id=${collabID}`);
      } catch (error) {
        console.error("Error during form submission:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.log("No files selected");
      setLoading(false);
    }
  };

  
  async function uploadFiles(files, transactionId) {
    const uploadTasks = Array.from(files).map((file) => {
      const fileRef = firebase.storage().ref(`sharedFiles/${file.name}`);
      return fileRef.put(file).then(snapshot => snapshot.ref.getDownloadURL());
    });
  
    const fileUrls = await Promise.all(uploadTasks);
    // Consider saving file metadata to your database if needed
    return fileUrls;
  }
  
  async function createUniqueCollab(details) {
    const uniqueCollabRef = firebase.database().ref('/uniqueCollabs').push();
    await uniqueCollabRef.set(details);
    return uniqueCollabRef.key;
  }
  
  return (
    <div className="GenPage">
      <div className="container">
        <h1>Set Up Collaboration</h1>
        <form onSubmit={handleFormSubmit}>
          <p className="info">Sent By:&nbsp;<span className="dynamic-text">{sellerEmail}</span></p>
          <p className="info">Amount: $<span className="dynamic-text">{amount}</span></p>
          <p className="info">Description:&nbsp;<span className="dynamic-text">{description}</span></p>
          <p className="info">Collab Deadline:&nbsp;<span className="dynamic-text">{deliveryTime} days</span></p>
          <textarea
            className="input"
            placeholder="Your message here"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <input
              type="file"
              className="input file-input"
              multiple
              onChange={handleFileChange}
          />
          <Button type="submit" className="GenLinkButton">Review and Pay</Button>
        </form>
        {loading && <p>Loading...</p>}
      </div>
    </div>
  );
}

export default CreateUniqueCollab;

import React from 'react'
import icon from '../images/icon-1.png';
import icon1 from '../images/icon2.png';
import icon2 from '../images/icon3.png';
import icon3 from '../images/icon4.png';
import icon5 from '../images/icon5.png';

const Collab = () => {
  return (
    <>
    <section class="collab_wrapper">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 mb-3">
          <div class="collabe-left-content">
            <img src={icon} alt="icon" />

            <h3 class="mt-3">Collaboration is the key</h3>
            <p>In the fast-paced world of hip-hop, collaboration is key. But managing these partnerships can be chaotic and risky. Enter Speedlab – your all-in-one solution for seamless creative transactions</p>
            {/*<button class="btn">Get Started Free</button>*/}
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="collabe-right-content">
            <h6>Say goodbye to scattered DMs, lost files, and payment headaches. With Speedlab, you can</h6>
            <div class="row mt-4">
              <div class="col-md-6 mb-3">
                <div class="collabe-box">
                  <img src="./images/icon2.png" alt="" />
                    <img src={icon1} alt="icon" />

                  <h5>Organize</h5>
                  <p>Organize all your collaborations in one secure platform</p>
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="collabe-box">
                  <img src={icon2} alt="icon" />

                  <h5>Customize</h5>
                  <p>Customize transactions for your unique services</p>
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="collabe-box">
                  <img src={icon3} alt="icon" />

                  <h5>Connect</h5>
                  <p>Connect directly with artists and consumers</p>
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="collabe-box">
                  <img src={icon5} alt="icon" />

                  <h5>Centralize</h5>
                  <p>Centralize files, payments, and records effortlessly</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
    </>
  )
}

export default Collab
// AuthContext.js
import React, { useContext, useState, useEffect, createContext } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// Initialize your Firebase app here (if not already initialized)
var firebaseConfig = {
  apiKey: "AIzaSyBQguFel0NDPxwC-KGJhfYdXvWJcdKMMxQ",
  authDomain: "speedlab1-7f02c.firebaseapp.com",
  databaseURL: "https://speedlab1-7f02c-default-rtdb.firebaseio.com",
  projectId: "speedlab1-7f02c",
  storageBucket: "speedlab1-7f02c.appspot.com",
  messagingSenderId: "298559435817",
  appId: "1:298559435817:web:5b3f63875ee9dda4ef5838",
  measurementId: "G-Y3H2W0K79V"
};
firebase.initializeApp(firebaseConfig);
//End
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

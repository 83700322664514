import React from 'react'
import line1 from '../images/line1.png';
import line2 from '../images/Line 2.png';



const Comparison = () => {
  return (
    <>
    <section class="camparison_wrapper">
    <div class="container">
      <div class="camp-header">
        <h3>Before and After Comparison</h3>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <div class="card">
            <h4 class="card-title">Before Speedlab</h4>
            <div class="card-body">
              <ul>
                <li>Scattered conversations across multiple platforms</li>
                <li>Payment confusion and security risks</li>
                <li>File management chaos</li>
                <li>Time wasted on administrative tasks</li>
                <li>Missed opportunities due to disorganization</li>
              </ul>
            </div>
            <div class="card-lines">
              <div class="line1">
                <img src={line1} alt="line" />

              </div>
              <div class="line2">
                <img src={line2} alt="line" />
              </div>
            </div>
          </div>
          
        </div>

        <div class="col-md-6 mb-3">
          <div class="card">
            <h4 class="card-title">With Speedlab</h4>
            <div class="card-body">
              <ul>
                <li>All communications centralized in one platform</li>
                <li>Secure, transparent payment processing</li>
                <li>Organized file storage and sharing</li>
                <li>More time for creating music</li>
                <li>Increased collaboration opportunities</li>
              </ul>
            </div>
            <div class="card-lines">
            <div class="line1">
                <img src={line1} alt="line" />

              </div>
              <div class="line2">
                <img src={line2} alt="line" />
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Comparison
import React, { useEffect } from 'react';
import axios from 'axios';
require('dotenv').config();

const PayPalButtonComponent = ({ amount, collabId, onSuccess }) => {
  useEffect(() => {
    const initializePayPalButtons = async () => {
      try {

        window.paypal.Buttons({
          createOrder: async (data, actions) => {
            try {
              console.log('Attempting to create order with amount:', amount);
              const res = await axios.post('/create-order', { amount });
              console.log('Order created:', res.data);
              return res.data.id;
            } catch (error) {
              console.error('Error creating order:', error);
              throw error;
            }
          },
          onApprove: async (data, actions) => {
            try {
              console.log('Order approved, capturing order...', data.orderID);
              const res = await axios.post('/capture-order', {
                orderID: data.orderID,
                collabId: collabId
              });
              console.log('Order captured:', res.data);
              if (res.data.success) {
                alert('Transaction completed by ' + res.data.payer.name.given_name);
                onSuccess();
              } else {
                alert('Payment verification failed.');
                console.log('Capture data:', res.data.captureData);
              }
            } catch (error) {
              console.error('Error capturing order:', error);
              throw error;
            }
          },
          onError: (err) => {
            console.error('PayPal Button render error:', err);
          }
        }).render('#paypal-button-container');
      } catch (error) {
        console.error('Failed to load the PayPal SDK script', error);
      }
    };

    initializePayPalButtons();
  }, [amount, collabId]);

  return (
    <div>
      <div id="paypal-button-container" style={{padding: '8px' }}></div>
    </div>
  );
};

export default PayPalButtonComponent;

// NavBar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext.js'; // Import the useAuth hook
import './NavBar.css';
import Button from './Button.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

function NavBar() {
    const { currentUser } = useAuth(); // Access the current user from context
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    if (currentUser === null) {
        console.log('current user is null')
    }

    const handleSignIn = () => {
        console.log("click on sign in")
        var provider = new firebase.auth.GoogleAuthProvider(); // Google Auth provider
        firebase.auth()
            .signInWithPopup(provider) // Use signInWithRedirect if you prefer
            .then((result) => {
                console.log(result);
                var user = result.user;
            }).catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
                console.error("Error signing in with Google:", errorMessage);
            });
    };

    const signOutUser = () => {
        firebase.auth().signOut().then(() => {
          console.log("User signed out successfully");
          // Handle successful signout (e.g., redirecting to home page)
        }).catch((error) => {
          console.error("Error signing out: ", error);
          // Handle sign out errors
        });
      };

    return (
        <>
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link to="/dashboard" className="navbar-logo" onClick={handleClick}>
                    <img src={'./sl-logo.png'} alt="Logo" className="logo" />
                </Link>
                <div className='navbar-small-cont'>
                    {currentUser ? (
                        <>
                            <h4 className='navbar-username'>{currentUser.displayName}</h4>
                            <Button className='navbar-button' onClick={signOutUser}>Log Out</Button>
                        </>
                    ) : (
                    <>
                    <Button className='navbar-button' onClick={handleSignIn}>Log in with Google</Button>
                    </>
                    )}
                </div>
            </div>
        </nav>
        </>
    )
}

export default NavBar;

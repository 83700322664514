import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const Button = ({ className, children, onClick }) => {
  return (
    <button className="genButton" onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;

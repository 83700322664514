import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './Pages.css';
import Button from '../Button.js';
import { useAuth } from '../../AuthContext.js';

function Dashboard() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(null);
  const [activeSubTab, setActiveSubTab] = useState(null);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (!currentUser) {
      navigate('/');
      return;
    }
    fetchTransactions(currentUser.uid);
    console.log('Current User:', currentUser);
  }, [currentUser, navigate]);

  const fetchTransactions = (uid) => {
    if (!uid) return;

    const transactionsRef = firebase.database().ref('/uniqueCollabs');
    transactionsRef.once('value', snapshot => {
      const transactionsData = snapshot.val() || {};
      const now = new Date();
      const userTransactions = Object.entries(transactionsData)
        .map(([key, transaction]) => ({ id: key, ...transaction }))
        .filter(transaction => new Date(transaction.deadline) >= now);

      const sellerTransactions = userTransactions.filter(transaction => transaction.sellerUID === uid);
      const buyerTransactions = userTransactions.filter(transaction => transaction.buyerUID === uid && transaction.sellerUID !== uid);

      console.log('Seller Transactions:', sellerTransactions);
      console.log('Buyer Transactions:', buyerTransactions);

      if (sellerTransactions.length > 0) {
        setActiveTab('seller');
        setActiveSubTab('seller-ongoing');
      } else if (buyerTransactions.length > 0) {
        setActiveTab('buyer');
        setActiveSubTab('buyer-ongoing');
      } else {
        setActiveTab('buyer'); // Default tab if no transactions found
        setActiveSubTab('buyer-ongoing');
      }

      setTransactions([...sellerTransactions, ...buyerTransactions]);
    }).catch(error => {
      console.error('Error fetching transactions:', error);
    });
  };

  if (!currentUser || activeTab === null) {
    return <div>Loading...</div>; // Render a loading state while determining active tab
  }

  const formatDate = (isoString) => {
    return new Date(isoString).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
  };

  const handleDetailsClick = (transaction) => {
    let detailPage;
    if (transaction.collabStatus === 'completed') {
      detailPage = 'collabpage';
    } else {
      detailPage = transaction.sellerUID === currentUser.uid ? 'sellercollabpage' : 'buyercollabpage';
    }
    navigate(`/${detailPage}?id=${transaction.id}`);
  };

  const renderTransactionTable = (role) => {
    const isCompletedTab = activeSubTab.includes('completed');
    const filteredTransactions = transactions.filter(transaction => {
      const isTransactionCompleted = transaction.collabStatus === 'completed';
      const roleMatch = role === 'seller' ? transaction.sellerUID === currentUser.uid : transaction.buyerUID === currentUser.uid;
      return roleMatch && (isCompletedTab ? isTransactionCompleted : !isTransactionCompleted);
    });

    if (filteredTransactions.length === 0) {
      return <tr><td colSpan="5">No transactions found.</td></tr>;
    }

    return filteredTransactions.map(transaction => (
      <tr key={transaction.id}>
        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', lineHeight: '1.5'  }}>{transaction.sellerEmail || 'N/A'}</td>
        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', lineHeight: '1.5'  }}>{transaction.buyerEmail || 'N/A'}</td>
        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', lineHeight: '1.5'  }}>{formatDate(transaction.deadline) || 'N/A'}</td>
        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', lineHeight: '1.5'  }}>{transaction.collabStatus === 'completed' ? 'Completed' : 'Ongoing'}</td>
        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', lineHeight: '1.5'  }}><button onClick={() => handleDetailsClick(transaction)}>Details</button></td>
      </tr>
    ));
  };

  return (
    <div className="dashboard">
      <div className="tabs">
        <Button onClick={() => setActiveTab('seller')} className={activeTab === 'seller' ? 'active' : ''}>Seller</Button>
        <Button onClick={() => setActiveTab('buyer')} className={activeTab === 'buyer' ? 'active' : ''}>Buyer</Button>
      </div>
      {
        activeTab === 'seller' && (
          <div className="container new-collab-template">
            <h1>New Collab Template</h1>
            <Button onClick={() => navigate('/createnewcollabtemplate')}>Create</Button>
          </div>
        )
      }
      <div className="sub-tabs container main-dashboard">
        <div className='sub-tabs-buttons'>
          <Button onClick={() => setActiveSubTab(activeTab + '-ongoing')} className={activeSubTab.includes('ongoing') ? 'active' : ''}>Ongoing</Button>
          <Button onClick={() => setActiveSubTab(activeTab + '-completed')} className={activeSubTab.includes('completed') ? 'active' : ''}>Completed</Button>
        </div>
        <h2 className='dashboard-table-header'>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} {activeSubTab.split('-')[1].charAt(0).toUpperCase() + activeSubTab.split('-')[1].slice(1)} Tab</h2>
        <div className='dashboard-table'>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#272326' }}>
                <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', lineHeight: '1.5'  }}>Seller</th>
                <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', lineHeight: '1.5'  }}>Buyer</th>
                <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', lineHeight: '1.5'  }}>Deadline</th>
                <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', lineHeight: '1.5'  }}>Transaction Status</th>
                <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', lineHeight: '1.5'  }}>Transaction Details</th>
              </tr>
            </thead>
            <tbody>{renderTransactionTable(activeTab)}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

import React from 'react'
import logo from '../images/logo.png';

const Header = () => {
  return (
    <>
    <div class="header">
      
      {/*
    <nav class="navbar navbar-expand-lg navbar-dark bg-transparent">
      <div class="container">
      <a className="navbar-brand" href="#">
        <img src={logo} alt="Logo" />
      </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">About Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Services <i class="fa-solid fa-chevron-down ms-1"></i></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
      */}

    <div class="banner">
      <div class="banner-content">
        <h1 class="display-4">Revolutionizing Creative <br class="br" /> Collaborations in Hip-Hop</h1>
        <p class="lead">Streamline Your Artistic Transactions, Secure Your Success</p>
        
      </div>
    </div>
  </div>
    </>
  )
}

export default Header